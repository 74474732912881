import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Refresh } from '@/assets/refresh.svg';
import RegisterValidationErrorMessage from '@/components/molecules/RegisterValidationErrorMessage';

import { ContentWrapper } from './styles';
import TwTitle from '@/components/atoms/TwTitle';
import TwText from '@/components/v2/atoms/TwText';

function CheckRegistration({ validated, error, isExternalFlow }) {
  return (
    <ContentWrapper isExternalFlow={isExternalFlow}>
      {!error ? (
        <>
          <div className="flex flex-col items-center justify-center gap-10 md:gap-16 size-9/12">
            <Refresh className="w-56" />
            <TwTitle text="Estamos validando sua identidade" />
            <TwText
              size="xl"
              text={`Este processo pode demorar alguns minutos.\nDepois de terminar, você poderá usar sua carteira Vpag.`}
            />
          </div>
        </>
      ) : (
        <RegisterValidationErrorMessage />
      )}
    </ContentWrapper>
  );
}

CheckRegistration.propTypes = {
  validated: PropTypes.bool,
}.isRequired;

export default CheckRegistration;
