import { useHistory, useLocation } from 'react-router-dom';
import getRouteByUserStatus from '@/helpers/functions';

function useUserStatus() {
  const history = useHistory();
  const { pathname } = useLocation();

  const getUserStatusAndRedirect = async (status) => {
    const newPathname = getRouteByUserStatus(status, pathname);

    if (newPathname !== pathname) {
      history.push(newPathname);
    }
  };
  return { getUserStatusAndRedirect };
}

export default useUserStatus;
