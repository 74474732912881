import React from 'react';
import PropTypes from 'prop-types';

function TwTitle({
  text = '',
  size = 'md',
  font = 'arboria-regular',
  tag: Tag,
  classList = '',
  color = 'white',
  children,
}) {
  const sizes = {
    xxs: 'text-[18px]',
    xs: 'text-[20px]',
    xl: 'text-[28px]',
    md: 'text-[32px]',
    lg: 'text-[45px]',
    d_md: 'text-[20px]',
    m_md: 'text-[18px]',
    d_lg: 'text-[32px]',
    m_lg: 'text-[26px]',
  };

  const fonts = {
    arboriaLight: 'font-arboria-light',
    arboriaRegular: 'font-arboria-regular',
    arboriaBold: 'font-arboria-bold',
  };

  const TagName = Tag || 'h1';

  return (
    <TagName
      className={`${sizes[size]} text-${color} ${fonts[font]} ${classList}`}
    >
      {children || text}
    </TagName>
  );
}

TwTitle.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.string,
  tag: PropTypes.elementType,
  font: PropTypes.string,
  color: PropTypes.string,
  classList: PropTypes.string,
  children: PropTypes.node,
};

TwTitle.defaultProps = {
  tag: 'h1',
};

export default TwTitle;
