import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PiX } from 'react-icons/pi';

import toast from 'react-hot-toast';
import IdentityValidationStatus from '@/components/organisms/IdentityValidationStatus';
import IFrameOcr from '@/components/organisms/IFrameOcr';
import TransferApiTemplate from '@/components/templates/TransferApiTemplate';
import IdwallSdk from '@/components/organisms/IdwallSdk';
import MainTemplate from '@/components/templates/MainTemplate';
import useUserStatus from '@/hooks/useUserStatus';
import { useUserData } from '@/context/userContext';
import { getPromocode, getUserInfo, retryValidation } from '@/services/api';
import { registrationStatus } from '@/helpers/constants';
import pendingRegisterImg from '@/assets/pending_register.jpg';

import { SdkWrapper } from './styles';
import CafValidation from '@/components/organisms/CafValidation';
import TwButton from '@/components/atoms/Buttons/TwButton';
import TwText from '@/components/v2/atoms/TwText';
import TwTitle from '@/components/atoms/TwTitle';
import useUpdateUser from '@/hooks/useUpdateUser';
import RegisterValidationErrorMessage from '@/components/molecules/RegisterValidationErrorMessage';
import i18n from '@/translate/i18n';
import { extractErrorSlug } from '@/helpers/functions';

const IDENTITY_IN_VALIDATION_CODE = 4;

function ValidateIdentity() {
  const [points, setPoints] = useState(0);
  const [showCompleteModal, setShowCompleteModal] = useState(true);
  const [hasToShowIframe, setHasToShowIframe] = useState(false);
  const { userData, setUserData } = useUserData();
  const { pathname } = useLocation();
  const { getUserStatusAndRedirect } = useUserStatus();
  const [documentRejected, setDocumentRejected] = useState(false);
  const { updateUserData } = useUpdateUser();

  const alreadyInValidation = userData?.status >= IDENTITY_IN_VALIDATION_CODE;
  const [error, setError] = useState('');
  const [documentSent, setDocumentSent] = useState(alreadyInValidation);
  const [retryIds, setRetryIds] = useState({ timeout: null, interval: null });
  const isExternalFlow = pathname.includes('/external');
  const PageTemplate = isExternalFlow ? TransferApiTemplate : MainTemplate;

  const clearTimers = () => {
    clearTimeout(retryIds.timeout);
    clearInterval(retryIds.interval);
  };

  const getUserPoints = async () => {
    try {
      const req = await getPromocode();
      setPoints(req.data.points);
    } catch (err) {
      console.error(err);
    }
  };

  const checkStatus = async (clearIntervals) => {
    const res = await getUserInfo();
    if (res?.data?.status === registrationStatus.validateId) {
      setError(true);
      clearIntervals();
    } else if (res?.data?.status >= registrationStatus.waitingIdValidation) {
      setDocumentSent(true);
      if (res?.data?.status === registrationStatus.waitingIdValidation) {
        updateUserData();
      }
    } else if (res?.data?.status === registrationStatus.validateRegistration) {
      setDocumentSent(false);
    }
  };

  const restart = () => {
    const request = retryValidation();
    toast.promise(request, {
      loading: i18n.t('validateIdentity.sending'),
      success: () => {
        const checker = () => {
          const intervalId = setInterval(() => {
            checkStatus(clearTimers);
            setRetryIds((state) => ({ ...state, interval: intervalId }));
          }, 15000);
        };

        const timeout = () => {
          const timeoutId = setTimeout(() => {
            checker();
          }, 6000);

          setRetryIds((state) => ({ ...state, timeout: timeoutId }));
        };

        timeout();
        setHasToShowIframe(false);
        setDocumentSent(false);
        setError(false);
        setUserData((state) => ({ ...state, status: 2 }));
        return i18n.t('validateIdentity.resetSuccess');
      },
      error: (err) => {
        const messages = extractErrorSlug(err?.response);
        return i18n.t([
          `error.resendEmail.${messages?.[0]}`,
          'error.resendEmail.unspecific',
        ]);
      },
    });
  };

  useEffect(() => {
    getUserStatusAndRedirect(userData?.status);
  }, [getUserStatusAndRedirect, userData]);

  useEffect(() => {
    let timeoutId;
    let intervalId;

    getUserPoints();

    if (userData?.status === registrationStatus.validateId) {
      setError(true);
    }

    const clearEffectTimers = () => {
      clearTimeout(timeoutId);
      clearInterval(intervalId);
    };

    const checker = () => {
      intervalId = setInterval(() => {
        checkStatus(clearEffectTimers);
      }, 15000);
    };

    const timeout = () => {
      timeoutId = setTimeout(() => {
        checker();
      }, 6000);
    };

    if (
      userData.status <= registrationStatus.waitingIdValidation &&
      userData.status !== registrationStatus.validateId
    ) {
      timeout();
    }

    if (userData?.status >= registrationStatus.validateId) {
      setShowCompleteModal(false);
    }

    return () => {
      clearEffectTimers();
      if (retryIds.interval) {
        clearTimers();
      }
    };
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    showCompleteModal &&
      userData?.status <= IDENTITY_IN_VALIDATION_CODE &&
      toast.success(i18n.t('registration.success'));
  }, [userData, showCompleteModal]);

  return (
    <>
      <PageTemplate
        containerProps={{ gradient: true, background: 'black' }}
        headerProps={{ mobileFullVersion: true, background: 'black' }}
        wrapperProps={{ background: 'black' }}
        hideHeader={isExternalFlow}
        hideNavMenu={isExternalFlow}
      >
        {error ? (
          <div className="flex flex-col justify-center items-center h-4/5 gap-10">
            <RegisterValidationErrorMessage restartValidation={restart} />
          </div>
        ) : !hasToShowIframe && !alreadyInValidation && !documentSent ? (
          <CafValidation renderIframe={() => setHasToShowIframe(true)} />
        ) : documentSent && !documentRejected ? (
          <IdentityValidationStatus
            isExternalFlow={isExternalFlow}
            setDocumentRejected={setDocumentRejected}
            alreadyInValidation={alreadyInValidation}
          />
        ) : (
          <SdkWrapper>
            {userData?.kyc_provider === 'idwall' ? (
              <IdwallSdk
                setErrorMessage={setError}
                onSuccess={() => setDocumentSent(true)}
              />
            ) : (
              <IFrameOcr
                ocrProviderSlug={userData?.kyc_provider}
                setError={setError}
                onSuccess={() => setDocumentSent(true)}
              />
            )}
          </SdkWrapper>
        )}
      </PageTemplate>
      {showCompleteModal && Boolean(points) && (
        <div className="fixed bg-black bg-opacity-70 size-full flex items-center justify-center">
          <div className="size-full md:size-[500px] relative">
            <img
              className="absolute size-full top-0 left-0"
              src={pendingRegisterImg}
              alt="validate your data"
            />
            <section className="absolute size-full flex items-center flex-col justify-center gap-20">
              <header className="flex">
                <div className="bg-white pl-4 pr-2 py-1 rounded-l-full">
                  <TwText text="Deu vitoria? deu vpag" color="black" />
                </div>
                <div className="bg-black pr-4 pl-2 py-1 rounded-r-full">
                  <TwText text="vpag.com" color="grass-800" />
                </div>
              </header>
              <main className="flex items-center flex-col justify-center gap-4">
                <div className="bg-black w-4/5 py-4 rounded-md">
                  <TwTitle text={`Ganhe ${points} pontos`} />
                </div>
                <div className="w-4/5">
                  <TwText
                    text="Troque por produtos na nossa loja! Válido apenas para sua
                  primeira transferência."
                  />
                </div>
                <div className="w-56 pt-6">
                  <TwButton
                    height="secondary"
                    label="Continuar"
                    onClick={() => setShowCompleteModal(false)}
                  />
                </div>
              </main>
            </section>
            <button
              type="button"
              className="absolute right-0 p-4 cursor-pointer"
              onClick={() => setShowCompleteModal(false)}
            >
              <PiX className="fill-grass-800 size-6" />
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default ValidateIdentity;
