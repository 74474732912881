import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import imageCompression from 'browser-image-compression';
import { Trans } from 'react-i18next';
import { toast } from 'react-hot-toast';

import BottomMenu from '@/components/organisms/BottomMenu';
import PopupMenu from '@/components/molecules/PopupMenu';
import ProfilePicDefault from '@/assets/userfull.svg';
import WebcamCapture from '@/components/organisms/WebcamCapture';
import i18n from '@/translate/i18n';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import { ReactComponent as AddressIcon } from '@/assets/addressIcon.svg';
import { ReactComponent as CamIcon } from '@/assets/camIcon.svg';
import { ReactComponent as PhoneIcon } from '@/assets/phoneIcon.svg';
import { useWalletData } from '@/context/walletContext';
import { convertUrlToFile } from '@/helpers/functions';
import useUpdateUser from '@/hooks/useUpdateUser';
import { getUserFitBankAccount, updateProfileImage } from '@/services/api';
import { useUserData } from '@/context/userContext';

import {
  ActionBtnWrapper,
  HiddenInputFile,
  ImageWrapper,
  TitleWrapper,
  UploadButton,
  UserProfilePic,
  Wrapper,
} from './styles';
import TwTitle from '@/components/atoms/TwTitle';

const profileIcon = {
  address: <AddressIcon />,
  phone: <PhoneIcon />,
};

const IMG_MAX_SIZE_IN_MB = 1.5;

function ProfileHeader({ address, phone, type, hideInMobile }) {
  const inputRef = useRef(null);
  const inputCameraRef = useRef(null);
  const webcamRef = useRef(null);
  const { isMobileLayout } = useDeviceWidth();

  const { isLoading, setIsLoading } = useWalletData();
  const [profilePic, setProfilePic] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [webcamMode, setWebcamMode] = useState(false);
  const [fitBank, setFitBank] = useState({});
  const { updateUserData } = useUpdateUser();
  const {
    userData: { email, id, profile_image_url: imageUrl },
  } = useUserData();

  function formatText(text) {
    const regex = /^(\d{7})(\d{1})$/;
    return text.replace(regex, '$1-$2');
  }

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const resizedImage = await imageCompression(profilePic, {
        maxSizeMB: IMG_MAX_SIZE_IN_MB,
      });

      const formData = new FormData();
      formData.append('image', resizedImage);
      await updateProfileImage(formData);
      updateUserData();
    } catch (err) {
      toast.error(
        i18n.t('error.unspecific', { id: 'update-interests-error-message' }),
      );
    } finally {
      setShowModal(false);
      setWebcamMode(false);
      setProfilePic(null);
      setIsLoading(false);
    }
  };

  const takeAPicture = async () => {
    const pic = webcamRef.current.getScreenshot();
    const imageFile = await convertUrlToFile(pic, 'user.png');
    setProfilePic(imageFile);
  };

  const menuOptionsBefore = [
    {
      label: i18n.t('profile.takeAPicture'),
      action: isMobileLayout
        ? () => inputCameraRef.current.click()
        : () => {
            setWebcamMode(true);
            setShowPopup(false);
          },
    },
    {
      label: i18n.t('profile.uploadPicture'),
      action: () => {
        inputRef.current.click();
        setShowPopup(false);
      },
    },
  ];

  const menuOptionsAfter = [
    {
      label: i18n.t('profile.savePicture'),
      action: handleSubmit,
    },
  ];

  const webCamOptions = [
    {
      label: profilePic
        ? i18n.t('profile.tryAgain')
        : i18n.t('profile.takeAPicture'),
      action: profilePic ? () => setProfilePic(null) : takeAPicture,
    },
    {
      label: i18n.t('profile.savePicture'),
      action: handleSubmit,
      disabled: !profilePic,
    },
  ];

  useEffect(() => {
    const controller = new AbortController();

    const getFitBankData = async () => {
      try {
        await getUserFitBankAccount(controller.signal).then((res) =>
          setFitBank(res.data.data),
        );
      } catch (error) {
        console.error(error);
      }
    };

    getFitBankData();

    return () => controller.abort();
  }, []);

  return (
    <Wrapper hideInMobile={hideInMobile}>
      <TwTitle
        text={i18n.t('profile.title')}
        size={isMobileLayout ? 'm_lg' : 'd_lg'}
        font="arboriaBold"
        classList="text-center"
      />
      <div>
        <ImageWrapper>
          {webcamMode ? (
            <WebcamCapture
              handleCancel={() => {
                setWebcamMode(false);
                setProfilePic(null);
              }}
              handleCapture={takeAPicture}
              handleSave={handleSubmit}
              image={profilePic}
              reset={() => {
                setProfilePic(null);
              }}
              webcamRef={webcamRef}
            />
          ) : (
            <>
              {!type ? (
                <UserProfilePic
                  alt="Profile Pic"
                  src={
                    profilePic
                      ? URL.createObjectURL(profilePic)
                      : imageUrl ?? ProfilePicDefault
                  }
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = ProfilePicDefault;
                  }}
                  id="user-profile-image"
                />
              ) : (
                profileIcon[type || 'profile']
              )}
              {!type && !webcamMode && !isLoading && (
                <UploadButton
                  type="button"
                  onClick={() => {
                    if (isMobileLayout) {
                      setShowModal((state) => !state);
                    } else {
                      setShowPopup(true);
                    }
                  }}
                  id="upload-profile-image-button"
                >
                  <CamIcon />
                </UploadButton>
              )}
            </>
          )}
          {showPopup && (
            <PopupMenu
              options={menuOptionsBefore}
              setShowModal={setShowPopup}
              style={{ bottom: -70, right: -170 }}
            />
          )}
        </ImageWrapper>
        {!webcamMode && !profilePic ? (
          <TitleWrapper>
            {!type && email && (
              <TwTitle
                size="small"
                title={email}
                id="profile-user-email"
                classList="mt-2"
              >
                {email}
              </TwTitle>
            )}
            {!type && id && (
              <TwTitle size="small" id="profile-user-id">
                ID: {id}
              </TwTitle>
            )}
            {Boolean(Object.keys(fitBank).length) && (
              <>
                <TwTitle size="small" id="profile-user-id">
                  {i18n.t('profile.bank')}: {fitBank.bank_code}
                </TwTitle>
                <TwTitle size="small" id="profile-user-id">
                  {i18n.t('profile.agency')}: {fitBank.branch}
                </TwTitle>
                <TwTitle size="small" id="profile-user-id">
                  {i18n.t('profile.account')}: {formatText(fitBank.number)}
                </TwTitle>
              </>
            )}
            {address && <TwTitle size="small">{address}</TwTitle>}
            {phone && <TwTitle size="small">{phone}</TwTitle>}
          </TitleWrapper>
        ) : (
          !webcamMode &&
          profilePic && (
            <ActionBtnWrapper>
              <button type="button" onClick={handleSubmit}>
                {i18n.t('actions.save')}
              </button>
              <button type="button" onClick={() => setProfilePic(null)}>
                {i18n.t('actions.cancel')}
              </button>
            </ActionBtnWrapper>
          )
        )}
      </div>
      <span />
      <BottomMenu
        title={
          <Trans
            i18n={i18n}
            i18nKey="profile.changeProfilePic"
            components={<span />}
          />
        }
        setShowModal={setShowModal}
        showModal={showModal}
        removeBlur={webcamMode}
        options={
          webcamMode
            ? webCamOptions
            : profilePic
            ? menuOptionsAfter
            : menuOptionsBefore
        }
        cancelAction={() => {
          setProfilePic(null);
          setShowModal(false);
          setWebcamMode(false);
        }}
      />
      <HiddenInputFile
        type="file"
        name="profilePic"
        ref={inputRef}
        accept="image/*"
        onChange={(e) => {
          setProfilePic(e.target.files[0]);
        }}
      />
      <HiddenInputFile
        type="file"
        name="profilePic"
        ref={inputCameraRef}
        accept="image/*"
        capture="user"
        onChange={(e) => {
          setProfilePic(e.target.files[0]);
        }}
      />
    </Wrapper>
  );
}

ProfileHeader.propTypes = {
  address: PropTypes.string,
  phone: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
}.isRequired;

export default ProfileHeader;
