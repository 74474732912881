import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { PiX } from 'react-icons/pi';
import {
  getTransactionByUuid,
  getUserInfo,
  getUserValidation,
} from '@/services/api';
import { registrationStatus } from '@/helpers/constants';
import useUpdateUser from '@/hooks/useUpdateUser';
import { useUserData } from '@/context/userContext';

import { ContentWrapper } from './styles';
import CheckRegistration from '@/components/molecules/CheckRegistration';
import TwButton from '@/components/atoms/Buttons/TwButton';
import TwText from '@/components/v2/atoms/TwText';
import TwTitle from '@/components/atoms/TwTitle';
import { ReactComponent as Logo } from '@/assets/vpag-simple-horizontal.svg';
import useUserPoints from '@/hooks/useUserPoints';

const TEN_SECONDS = 10000;
const validationFields = ['facematch', 'ocr'];

function IdentityValidationStatus({ isExternalFlow, alreadyInValidation }) {
  const history = useHistory();
  const { userData, setUserData } = useUserData();
  const [userValidations, setUserValidations] = useState([]);
  const [isValidated, setIsValidate] = useState(false);
  const [error, setError] = useState(false);
  const { updateUserData } = useUpdateUser();
  const { getPoints } = useUserPoints();

  const checkValidations = () => {
    const fieldsToCheck = userValidations.filter((item) =>
      validationFields.includes(item?.validation),
    );

    const validationsCreated =
      fieldsToCheck?.length === validationFields.length;

    if (
      validationsCreated &&
      fieldsToCheck.some((item) => item?.status === 'rejected')
    ) {
      setError(true);
    }
  };

  const handleStart = async () => {
    await updateUserData();
    await getPoints();
  };

  const handleCompleteValidations = async () => {
    const pendingTransferUuid = sessionStorage.getItem('pendingApiTransaction');
    try {
      const res = await getTransactionByUuid(pendingTransferUuid);

      const { transaction, merchant } = res.data;
      localStorage.setItem(
        'transaction',
        JSON.stringify({ ...transaction, merchant }),
      );
      setUserData((state) => ({
        ...state,
        status: registrationStatus.validated,
        kyc_level: 1,
      }));

      if (transaction?.type === 'transfer-in') {
        history.push('/external/deposit');
      } else {
        history.push(`/transfer-api/${pendingTransferUuid}`);
      }
    } catch (err) {
      history.push(`/transfer-api/${pendingTransferUuid}`);
    }
  };

  useEffect(() => {
    const onValidate = () => {
      if (isExternalFlow) {
        handleCompleteValidations();
      } else if (!isValidated) {
        setIsValidate(true);
      }
    };

    const updateStatus = async () => {
      try {
        const res = await getUserValidation(userData?.id);
        if (!isExternalFlow) {
          await getUserInfo().then((user) => {
            if (registrationStatus.validated === user?.data?.status) {
              onValidate();
            }
          });
        }
        setUserValidations(res.data);
      } catch (err) {
        console.error(err?.response?.data?.message);
      }
    };
    const interval = setInterval(() => updateStatus(), TEN_SECONDS);

    if (userData?.status === registrationStatus.validated) {
      onValidate();
    }

    if (isValidated || error) {
      clearInterval(interval);
    }
    updateStatus();

    return () => {
      clearInterval(interval);
    };
  }, [isValidated, error]);

  useEffect(() => {
    if (userValidations?.length) {
      checkValidations();
    }
  }, [userValidations]);

  return (
    <>
      <ContentWrapper isExternalFlow={isExternalFlow}>
        <CheckRegistration validated={isValidated || alreadyInValidation} />
      </ContentWrapper>
      {isValidated && (
        <div className="fixed bg-black bg-opacity-50 top-0 left-0 size-full flex items-center justify-center">
          <div className="size-full md:size-[500px] relative bg-validationBackground bg-cover">
            <section className="absolute size-full flex items-center flex-col justify-center gap-24">
              <div className="flex flex-col items-center gap-5">
                <Logo />
                <header className="flex">
                  <div className="bg-white pl-4 pr-2 py-1 rounded-l-full">
                    <TwText text="Deu vitória? Deu vpag" color="black" />
                  </div>
                  <div className="bg-black pr-4 pl-2 py-1 rounded-r-full">
                    <TwText text="vpag.com" color="grass-800" />
                  </div>
                </header>
              </div>
              <main className="flex items-center flex-col justify-center gap-4">
                <div className="bg-grass-800 px-4 pb-1 rounded-md">
                  <TwTitle color="black" text="Parabéns!" />
                </div>
                <div>
                  <TwText text="Sua conta foi validada, disfrute da vpag!" />
                </div>
                <div className="w-56 pt-14 flex flex-col gap-2">
                  <TwButton
                    height="secondary"
                    label="Começar"
                    onClick={handleStart}
                  />
                  <span className="text-white underline cursor-pointer">
                    Conhece vpag
                  </span>
                </div>
              </main>
            </section>
            <button
              type="button"
              className="absolute right-0 p-4 cursor-pointer"
            >
              <PiX className="fill-white size-6" onClick={handleStart} />
            </button>
          </div>
        </div>
      )}
    </>
  );
}

IdentityValidationStatus.propTypes = {
  isExternalFlow: PropTypes.bool,
  alreadyInValidation: PropTypes.bool,
};

export default IdentityValidationStatus;
