import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import CustomToast from '@/components/molecules/CustomToast';
import ExpiredSession from '@/components/molecules/CustomToast/ExpiredSession';
import LoginRegisterSelector from '@/components/molecules/LoginRegisterSelector';
import RegisterTemplate from '@/components/templates/RegisterTemplate';
import SignInForm from '@/components/organisms/SignInForm';

import { RightWrapper } from './styles';

function SignIn() {
  const { id } = useParams();
  const { pathname } = useLocation();
  const [showExpiredSession, setShowExpiredSession] = useState(false);

  const isExternalLogin = pathname?.includes('/transactions/signin');
  localStorage.removeItem('alertDisplayed');

  if (id) {
    sessionStorage.setItem('pendingApiTransaction', id);
  }

  const closeModal = () => setShowExpiredSession(false);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('show_expired_session'))) {
      setShowExpiredSession(true);
      localStorage.setItem('show_expired_session', false);
    }

    return () => {
      closeModal();
    };
  }, []);

  return (
    <RegisterTemplate>
      <CustomToast
        isOpen={showExpiredSession}
        close={closeModal}
        type="sessionExpired"
      >
        <ExpiredSession />
      </CustomToast>
      <RightWrapper>
        <LoginRegisterSelector isExternalLogin={isExternalLogin} />
        <SignInForm transactionId={id} />
      </RightWrapper>
    </RegisterTemplate>
  );
}

export default SignIn;
