import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import CheckboxInput from '@/components/atoms/Checkbox';
import Select from '@/components/atoms/Select';
import { Paragraph } from '@/components/atoms/Typography/styles';
import i18n from '@/translate/i18n';
import { Button } from '@/components/atoms/Buttons/Button/styles';
import { TextButton } from '@/components/atoms/Buttons/TextButton/styles';

import {
  ButtonsWrapper,
  CheckBoxWrapper,
  Container,
  Content,
  InputWrapper,
} from './styles';

const LAST_WEEK_DATE = moment().subtract(7, 'd').format('YYYY-MM-DD');
const LAST_MONTH_DATE = moment().subtract(30, 'd').format('YYYY-MM-DD');

const periodOptions = [
  { label: i18n.t('pointsHistory.filter.all'), value: '' },
  { label: i18n.t('pointsHistory.filter.lastWeek'), value: LAST_WEEK_DATE },
  { label: i18n.t('pointsHistory.filter.lastMonth'), value: LAST_MONTH_DATE },
];

function PointsFilter({
  currentFilters,
  handleSubmit,
  initialFilter,
  setCurrentFilters,
  setShowFilter,
  showFilter,
}) {
  const [newFilters, setNewFilters] = useState(currentFilters);

  const handleChange = ({ name, checked }) => {
    const { type } = newFilters;

    if (checked) {
      setNewFilters({ ...newFilters, type: [...type, name] });
    } else if (type.length > 1) {
      setNewFilters({
        ...newFilters,
        type: type.filter((el) => el !== name),
      });
    }
  };

  const resetFilter = () => {
    setCurrentFilters(initialFilter);
    setNewFilters(initialFilter);
  };

  const isDirty = JSON.stringify(currentFilters) !== JSON.stringify(newFilters);
  const isFiltered =
    JSON.stringify(newFilters) !== JSON.stringify(initialFilter);
  const filtersChanged =
    JSON.stringify(currentFilters) !== JSON.stringify(initialFilter);

  return (
    <>
      <Container showFilter={showFilter} id="points-filter">
        <Content>
          <CheckBoxWrapper>
            <CheckboxInput
              id="transaction"
              name="transaction"
              label={i18n.t('pointsHistory.filter.transaction')}
              checked={newFilters?.type?.includes('transaction')}
              onChange={({ target }) => handleChange(target)}
            />
            <CheckboxInput
              id="store"
              name="store"
              label={i18n.t('pointsHistory.filter.store')}
              checked={newFilters?.type?.includes('store')}
              onChange={({ target }) => handleChange(target)}
            />
            <CheckboxInput
              id="affiliate"
              name="affiliate"
              label={i18n.t('pointsHistory.filter.affiliate')}
              checked={newFilters?.type.includes('affiliate')}
              onChange={({ target }) => handleChange(target)}
            />
            <CheckboxInput
              id="addpoint"
              name="addpoint"
              label={i18n.t('pointsHistory.filter.addpoint')}
              checked={newFilters?.type?.includes('addpoint')}
              onChange={({ target }) => handleChange(target)}
            />
          </CheckBoxWrapper>

          <InputWrapper>
            <Paragraph family="secondary" color="title">
              {i18n.t('pointsHistory.filter.period')}
            </Paragraph>
            <Select
              options={periodOptions}
              id="created_at_from"
              onChange={({ target: t }) => {
                setNewFilters({ ...newFilters, created_at_from: t.value });
              }}
              color="light"
              variant="rounded"
            />
          </InputWrapper>
          {isFiltered && (
            <ButtonsWrapper>
              <Button
                type="button"
                disabled={!isDirty}
                onClick={() => {
                  setShowFilter(false);
                  setCurrentFilters(newFilters);
                  handleSubmit(newFilters, null);
                }}
              >
                {i18n.t('transactionHistory.apply')}
              </Button>
              <TextButton
                type="button"
                onClick={() => {
                  resetFilter();
                  setShowFilter(false);
                  if (filtersChanged) {
                    handleSubmit(initialFilter, null);
                  }
                }}
              >
                {i18n.t('transactionHistory.clear')}
              </TextButton>
            </ButtonsWrapper>
          )}
        </Content>
      </Container>
    </>
  );
}

PointsFilter.propTypes = {
  setCurrentFilters: PropTypes.func,
  setShowFilter: PropTypes.func,
  filterParams: PropTypes.shape({
    type: PropTypes.string,
    status: PropTypes.string,
    startAt: PropTypes.string,
    endDate: PropTypes.string,
  }),
}.isRequired;

export default PointsFilter;
