import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import ErrorMessage from '@/components/molecules/ErrorMessage';
import Loading from '@/components/atoms/Loading';
import SectionDivider from '@/components/atoms/SectionDivider/styles';
import PointsListItem from '@/components/molecules/PointsListItem';
import PointsFilter from '@/components/organisms/PointsFilter';
import MainTemplate from '@/components/templates/MainTemplate';
import { useUserData } from '@/context/userContext';
import i18n from '@/translate/i18n';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import { ReactComponent as FilterIcon } from '@/assets/filterIcon.svg';
import { Paragraph } from '@/components/atoms/Typography/styles';
import { listUserPointsRecord } from '@/services/api';

import {
  ContentWrapper,
  FilterButton,
  ListWrapper,
  PaginationWrapper,
  Section,
} from './styles';
import TwTitle from '@/components/atoms/TwTitle';

const ITEMS_PER_PAGE = 10;
const ITEMS_PER_PAGE_MOBILE = 10;
const NUM_OF_PAGES_DEFAULT = 2;

function PointsHistory() {
  const initialFilter = {
    order: 'desc',
    order_by: 'id',
    page: 1,
    type: ['affiliate', 'store', 'transaction', 'addpoint'],
    user_id: [JSON.parse(localStorage.getItem('id'))],
  };

  const { userData } = useUserData();
  const { isMobileLayout } = useDeviceWidth();
  const [pageNumber, setPageNumber] = useState(1);
  const [pointList, setPointList] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentFilters, setCurrentFilters] = useState(initialFilter);
  const [pageCount, setPageCount] = useState(NUM_OF_PAGES_DEFAULT);
  const [isLoading, setIsLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const getPointHistory = async (newFilters = {}, signal) => {
    try {
      setIsLoading(true);

      let params = {
        per_page: isMobileLayout ? ITEMS_PER_PAGE_MOBILE : ITEMS_PER_PAGE,
        ...newFilters,
      };

      if (newFilters?.type?.includes('addpoint')) {
        params = { ...params, type: [...params.type, 'topup'] };
      } else {
        params = {
          ...params,
          type: params.type.filter((el) => el !== 'topup'),
        };
      }

      const res = await listUserPointsRecord(params, signal);

      setPointList(res.data);
      setPageCount(Math.ceil(res.meta.total / ITEMS_PER_PAGE));
    } catch (err) {
      const message = err?.response?.data?.message;
      setErrorMessage(
        i18n.t([
          `error.transactionHistory.${message}`,
          'error.transactionHistory.unspecific',
        ]),
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    getPointHistory({ ...currentFilters, page: pageNumber }, controller.signal);

    return () => controller.abort();
  }, [pageNumber]);

  const changePage = ({ selected }) => {
    setPageNumber(selected + 1);
  };

  const isFiltered =
    JSON.stringify(initialFilter) !== JSON.stringify(currentFilters);

  return (
    <MainTemplate
      headerProps={{
        returnTo: '/wallet',
        title: i18n.t('transactions.title'),
        returnAction: showFilter
          ? () => {
              setShowFilter(false);
            }
          : null,
        customButton: !showFilter && (
          <FilterButton>
            <FilterIcon
              onClick={() => {
                setShowFilter((state) => !state);
              }}
            />
          </FilterButton>
        ),
      }}
    >
      <ContentWrapper>
        <Section hideInMobile={showFilter}>
          <TwTitle
            size={isMobileLayout ? 'm_lg' : 'd_lg'}
            font="arboriaBold"
            classList="text-center"
          >
            {i18n.t('pointsHistory.balance')}
            <span className="text-grass-800">{userData?.points}</span>
          </TwTitle>
          {!isLoading && !errorMessage.length ? (
            <ListWrapper
              id="transactions-list"
              justifyContent={!pointList.length && 'center'}
            >
              {errorMessage ? (
                <ErrorMessage content={errorMessage} />
              ) : (
                pointList.map((el) => (
                  <PointsListItem key={el.id} points={el} />
                ))
              )}
              {!isLoading && !errorMessage.length && !pointList.length && (
                <Paragraph textAlign="center" style={{ margin: '0 auto' }}>
                  {i18n.t(
                    isFiltered
                      ? 'transactionHistory.emptyData'
                      : 'pointsHistory.noData',
                  )}
                </Paragraph>
              )}
            </ListWrapper>
          ) : (
            <Loading />
          )}
          {Boolean(pageCount) && !isLoading && (
            <PaginationWrapper>
              <ReactPaginate
                breakLabel=""
                nextLabel={<IoIosArrowForward />}
                previousLabel={<IoIosArrowBack />}
                disableInitialCallback
                forcePage={pageNumber - 1}
                pageCount={pageCount}
                onPageChange={changePage}
                marginPagesDisplayed={1}
                pageRangeDisplayed={NUM_OF_PAGES_DEFAULT}
                activeLinkClassName="current-page"
                disabledClassName="button-disabled"
                pageLinkClassName="page-link"
                previousLinkClassName="prev-button"
                nextLinkClassName="next-button"
              />
            </PaginationWrapper>
          )}
        </Section>
        <SectionDivider height="75%" />
        {(showFilter || !isMobileLayout) && (
          <Section>
            <TwTitle
              text={i18n.t('transactionHistory.filters')}
              size={isMobileLayout ? 'm_lg' : 'd_lg'}
              font="arboriaBold"
              classList="text-center"
            />
            <PointsFilter
              currentFilters={currentFilters}
              handleSubmit={getPointHistory}
              initialFilter={initialFilter}
              setCurrentFilters={setCurrentFilters}
              setShowFilter={setShowFilter}
              showFilter={showFilter}
            />
            <span />
          </Section>
        )}
      </ContentWrapper>
    </MainTemplate>
  );
}

export default PointsHistory;
