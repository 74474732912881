import React from 'react';
import PropTypes from 'prop-types';

import LoadingDark from '@/assets/loading-dark.gif';
import LoadingGreen from '@/assets/loading-green.gif';
import i18n from '@/translate/i18n';
import { Image, LoadingText, Wrapper } from './styles';

function Loading({
  content,
  disableFlexGrow,
  hideInDesktop,
  isDark,
  marginTop,
}) {
  return (
    <Wrapper
      marginTop={marginTop}
      hideInDesktop={hideInDesktop}
      id="loading-element"
      disableFlexGrow={disableFlexGrow}
    >
      {isDark ? (
        <Image src={LoadingDark} alt="Loading" />
      ) : (
        <Image src={LoadingGreen} alt="Loading" />
      )}
      <LoadingText isDark={isDark}>
        {content || i18n.t('loading.processing')}
      </LoadingText>
    </Wrapper>
  );
}

Loading.propTypes = {
  content: PropTypes.string,
  disableFlexGrow: PropTypes.bool,
  hideInDesktop: PropTypes.bool,
  isDark: PropTypes.bool,
  marginTop: PropTypes.string,
};

export default Loading;
