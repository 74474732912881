import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import LoginRegisterSelector from '@/components/molecules/LoginRegisterSelector';
import SignUpForm from '@/components/organisms/SignUpForm';
import { RightWrapper } from '@/components/pages/SignIn/styles';
import RegisterTemplate from '@/components/templates/RegisterTemplate';

function SignUp() {
  const { pathname } = useLocation();
  const isExternalLogin = pathname?.includes('/external');
  const [isEmailValid, setIsEmailValid] = React.useState(false);
  const [stateProgressBar, setStateProgressBar] = useState('signUp');
  return (
    <RegisterTemplate>
      <RightWrapper>
        <LoginRegisterSelector
          isExternalLogin={isExternalLogin}
          origin="signUp"
          isCreateAccount
          isEmailValid={isEmailValid}
          stateProgressBar={stateProgressBar}
        />
        <SignUpForm
          setEmailValid={setIsEmailValid}
          setStateProgressBar={setStateProgressBar}
        />
      </RightWrapper>
    </RegisterTemplate>
  );
}

export default SignUp;
