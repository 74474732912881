import styled from 'styled-components';

import { ReactComponent as Success } from '@/assets/checked.svg';

export const ContentWrapper = styled.div`
  align-items: center;
  background-image: ${({ theme, isExternalFlow }) =>
    isExternalFlow ? 'none' : `black`};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  padding: 1rem;
  text-align: center;
  width: 100%;

  p {
    max-width: 390px;
  }

  div {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    background-image: none;
    width: auto;
  }
`;

export const SuccessIcon = styled(Success)`
  width: 70px;
  height: 70px;
`;
